<template>
  <div>
    <div class="form-check" v-for="(param, index) in filter_options.params" :key="index">
      <input class="form-check-input" type="checkbox" :id="filter_options.name + index" :checked="param.val" v-model="param.val" @change="$parent.beers_show_list()">
      <label class="form-check-label" :for="filter_options.name + index">
        {{ param.name }}
      </label>
    </div>
  </div>
</template>
<script>
import FilterHelper from '@/components/helpers/FilterHelper.js'
  export default {
    props:['filter_options']
  }
</script>
<style>

</style>