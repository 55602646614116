<template>
  <div class="modal fade" id="modal-shopcart" tabindex="-1" aria-labelledby="exampleModalFullscreenLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title h4" id="exampleModalFullscreenLabel">Корзина <span class="badge rounded-pill bg-warning text-dark">{{ products_count }}</span></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="list-wrap">
            <div v-if="products_count == 0">
              <h5> Корзина пустая </h5>
            </div>
            <!--Single Beer-->
            <template v-for="(product, index) in $store.getters.SHOP_CART">
              <div class="list-group-item list-group-item-action <!--active-->" aria-current="true">
                <div class="d-flex w-100 justify-content-start">
                  <div class="und-img">
                    <a href="#!" class="to-single">
                      <img :src="product.img_link" alt="" class="w-100 mb-2">
                    </a>
                  </div>
                  <div class="w-100">
                    <a href="#!" class="to-single">
                      <h5 class="mb-1">{{ product.name }}</h5>
                    </a>
                    <p class="dots">
                      <span class="size">{{ product.boxing }}</span>
                      <span class="price">{{ product.price }}</span>
                    </p>
                    <div class="order-info d-flex justify-content-between">
                      <div class="counter">
                        <button type="button" class="btn btn-secondary btn-sm" @click="minus_product(product)"><i class="fas fa-minus"></i></button>
                        <a class="px-2"> {{ product.quantity }} </a>
                        <button type="button" class="btn btn-secondary btn-sm yellow" @click="plus_product(product)"><i class="fas fa-plus"></i></button>
                      </div>
                      <a href="#!" @click="removeProduct(index)"> <i class="far fa-trash-alt"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!--/Single Beer-->


          </div>
          <br>
          <span class="" style="color: #616161">
                    <small>
                        Подробнее о том как <a href="/page-buy.php">купить</a> и <a href="/page-delivery.php">доставке</a>
                    </small>
                </span>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div class="price-result pl-1">
            <small class="size">Итого:</small>{{ price_counter }} р.
<!--            <small class="size cashback"> <span class="">+20</span>  баллов</small>-->
          </div>
          <button type="button" class="btn btn-warning" data-bs-dismiss="modal">ЗАКАЗАТЬ</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods:{
    plus_product:function(product){
      product.quantity++
    },
    minus_product:function(product){
      if(product.quantity>1){
        product.quantity--
      }
    },
    removeProduct:function(index){
      this.$store.getters.SHOP_CART.splice(index,1)
    },
  },
  computed:{
    products_count:function(){
      return this.$store.getters.SHOP_CART.length
    },
    price_counter:function(){
      let res = 0
      this.$store.getters.SHOP_CART.map(beer => res += beer.price * beer.quantity)
      return  res
    },
  }
}
</script>
<style>
.price-result{
  border-radius: 6px;
  font-family: Roboto-Medium;
}
.und-img{
  height: auto;
}
.counter button{
  border: none;
}
.order-info a{
  color: #424242;
}
.cashback{
  /*background: #F4FF81;*/
  color: #64DD17
}
</style>