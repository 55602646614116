export default {
    actions:{
        set_selected(context, dev) {
            context.commit('set_selected', dev);
        }
    },
    mutations:{
        set_filtered_beers(state, val){
            state.filtered_beers = val
        },
        set_show_filtered_beers(state, val){
            state.show_filtered_beers = val
        },
        set_filter_param_price(state, val){
            state.filter_param.price  = val
        },
        set_beers_show_list(state, val){
            state.beers_show_list  = val
        },
        set_cash_filter_options(state, val){
            state.cash_filter_options  = val
        },
        set_filter_options(state, val){
            state.filter_options  = val
        },
        set_shopcart(state, val){
            val.quantity = 1
            state.shopcart.push(val)
        },
    },
    state: {
        shopcart:[],
        filter_options:[
            {
                name: "country",
                title: "Страна",
                type: "checkbox",
                params: [
                    {
                        name: 'Russia',
                        val: true
                    },
                    {
                        name: 'Germany',
                        val: true
                    },
                    {
                        name: 'Czech Republic',
                        val: true
                    },
                    {
                        name: 'Belgium',
                        val: true
                    },
                ]
            },
            {
                name: "brewery",
                title: "Пивоварня",
                type: "checkbox",
                params:[
                    {
                        name: 'Пивовар 1',
                        val: true
                    },
                    {
                        name: 'Пивовар 2',
                        val: true
                    },
                    {
                        name: 'Пивовар 3',
                        val: true
                    },
                    {
                        name: 'Пивовар 4',
                        val: true
                    },
                    {
                        name: 'Пивовар 5',
                        val: true
                    },
                ]
            },
            {
                name: "style",
                title: "Стиль",
                type: "checkbox",
                params:[
                    {
                        name: 'Стиль один',
                        val: true
                    },
                    {
                        name: 'Стиль два',
                        val: true
                    },
                    {
                        name: 'Стиль три',
                        val: true
                    },
                    {
                        name: 'Стиль четыре',
                        val: true
                    },
                    {
                        name: 'Стиль пять',
                        val: true
                    },
                ]
            },
            {
                name: "boxing",
                title: "Формат",
                type: "checkbox",
                params:[
                    {
                        name: '500ml бутылка',
                        val: true
                    },
                    {
                        name: '300ml бутылка',
                        val: true
                    },
                    {
                        name: '300ml банка',
                        val: true
                    },
                    {
                        name: '1L разлив',
                        val: true
                    }
                ]
            },
            {
                name: "tags",
                title: "Тэги",
                type: "checkbox",
                params:[
                    {
                        name:  'Stout - Imperial',
                        val: true
                    },
                    {
                        name:  'IPA - Triple',
                        val: true
                    },
                    {
                        name:  'IPA',
                        val: true
                    },
                ]
            },
            {
                name: "ibu",
                title: "IBU",
                type: "slider",
                params:
                    {
                        min: 9,
                        max: 90,
                        val: [9, 90],
                    }
            },
            {
                name: "abv",
                title: "ABV",
                type: "slider",
                params: {
                    min:2.5,
                    max:8,
                    val: [2.5,8]
                }
            },
            {
                name: "untappd_stars",
                title: "Рейтинг Untappd",
                type: "stars",
                params:{
                    all: [1,2,3,4,5],
                    selected: [1,2,3,4,5]
                }
            },
            {
                name: "price",
                title: "Цена",
                type: "slider",
                params:{
                    min:100,
                    max:270,
                    val: [100,270],
                }
            }
        ],
        cash_filter_options:[],
        beers_show_list: {
            country: ['Russia', 'Germany'],
            brewery:['Пивовар 1'],
            style:['Стиль один'],
            boxing:['500ml бутылка'],
            tags:['Stout - Imperial'],
            ibu:[1, 30],
            abv:[1, 10],
            price:[1,2000],
            untappd_stars:[1,2,3]
    },
        filter_param:{
            country: [
                {
                    name: 'Russia',
                    val: true
                },
                {
                    name: 'Germany',
                    val: true
                },
                {
                    name: 'Czech Republic',
                    val: true
                },
                {
                    name: 'Belgium',
                    val: true
                },
            ],
            brewery: [
                {
                    name: 'Пивовар 1',
                    val: true
                },
                {
                    name: 'Пивовар 2',
                    val: true
                },
                {
                    name: 'Пивовар 3',
                    val: true
                },
                {
                    name: 'Пивовар 4',
                    val: true
                },
            ],
            style: [
                {
                    name: 'Стиль один',
                    val: true
                },
                {
                    name: 'Стиль два',
                    val: true
                },
                {
                    name: 'Стиль три',
                    val: true
                }
            ],
            boxing: [
                {
                    name: '500ml бутылка',
                    val: true
                },
                {
                    name: '300ml бутылка',
                    val: true
                },
                {
                    name: '500ml банка',
                    val: true
                }
            ],
            /*tags: ['Stout - Imperial'],*/
            ibu: {
                min:0,
                max:100,
                val: [0,100],
            },
            abv: {
                min:1,
                max:8,
                val: [1,8],
            },
            untappd_stars: [3.00],
            price: {
                min:120,
                max:270,
                val: [120,270],
            },
        },
        filtered_beers:[],
        show_filtered_beers:[],
        data: {
            beers: [
                {
                    id: 1,
                    name: 'Название 1',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 1',
                    style: 'Стиль один',
                    boxing: '500ml бутылка',
                    tags: 'Stout - Imperial',
                    ibu: 9,
                    abv: 4.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.00,
                    price: '110',
                    catalog_name: '',
                    img_link: 'assets/img/beers/1.jpeg',
                    photos: [],
                },
                {
                    id: 2,
                    name: 'Название 2',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 3',
                    style: 'Стиль один',
                    boxing: '500ml бутылка',
                    tags: 'IPA - Triple',
                    ibu: 10,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.5,
                    price: '130',
                    catalog_name: '',
                    img_link: 'assets/img/beers/2.jpeg',
                    photos: [],
                },
                {
                    id: 3,
                    name: 'Название 3',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 1',
                    style: 'Стиль два',
                    boxing: '500ml бутылка',
                    tags: 'Stout - Imperial',
                    ibu: 40,
                    abv: 6.0,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.43,
                    price: '140',
                    catalog_name: '',
                    img_link: 'assets/img/beers/3.jpeg',
                    photos: [],
                },
                {
                    id: 4,
                    name: 'Название 4',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 1',
                    style: 'Стиль четыре',
                    boxing: '500ml бутылка',
                    tags: 'IPA - Triple',
                    ibu: 10,
                    abv: 6.0,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 5.0,
                    price: '120',
                    catalog_name: '',
                    img_link: 'assets/img/beers/4.jpeg',
                    photos: [],
                },
                {
                    id: 5,
                    name: 'Название 5',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 3',
                    style: 'Стиль три',
                    boxing: '300ml бутылка',
                    tags: 'Stout - Imperial',
                    ibu: 40,
                    abv: 8.0,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 5.0,
                    price: '150',
                    catalog_name: '',
                    img_link: 'assets/img/beers/5.jpeg',
                    photos: [],
                },
                {
                    id: 6,
                    name: 'Название 6',
                    desc: '',
                    country: 'Czech Republic',
                    brewery: 'Пивовар 4',
                    style: 'Стиль два',
                    boxing: '300ml бутылка',
                    tags: 'IPA',
                    ibu: 80,
                    abv: 4.0,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.0,
                    price: '130',
                    catalog_name: '',
                    img_link: 'assets/img/beers/6.jpeg',
                    photos: [],
                },
                {
                    id: 7,
                    name: 'Название 7',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 2',
                    style: 'Стиль четыре',
                    boxing: '300ml бутылка',
                    tags: 'IPA - Triple',
                    ibu: 30,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.20,
                    price: '100',
                    catalog_name: '',
                    img_link: 'assets/img/beers/7.jpeg',
                    photos: [],
                },
                {
                    id: 8,
                    name: 'Название 8',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 2',
                    style: 'Стиль два',
                    boxing: '300ml бутылка',
                    tags: 'IPA - Triple',
                    ibu: 70,
                    abv: 5.0,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.50,
                    price: '160',
                    catalog_name: '',
                    img_link: 'assets/img/beers/8.jpeg',
                    photos: [],
                },
                {
                    id: 9,
                    name: 'Название 9',
                    desc: '',
                    country: 'Czech Republic',
                    brewery: 'Пивовар 5',
                    style: 'Стиль четыре',
                    boxing: '300ml банка',
                    tags: 'Stout - Imperial',
                    ibu: 90,
                    abv: 5.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.43,
                    price: '130',
                    catalog_name: '',
                    img_link: 'assets/img/beers/9.jpeg',
                    photos: [],
                },
                {
                    id: 10,
                    name: 'Название 10',
                    desc: '',
                    country: 'Czech Republic',
                    brewery: 'Пивовар 2',
                    style: 'Стиль три',
                    boxing: '300ml банка',
                    tags: 'IPA - Triple',
                    ibu: 20,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.70,
                    price: '110',
                    catalog_name: '',
                    img_link: 'assets/img/beers/10.jpeg',
                    photos: [],
                },
                {
                    id: 11,
                    name: 'Название 11',
                    desc: '',
                    country: 'Belgium',
                    brewery: 'Пивовар 2',
                    style: 'Стиль два',
                    boxing: '300ml банка',
                    tags: 'IPA - Triple',
                    ibu: 20,
                    abv: 4.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.40,
                    price: '150',
                    catalog_name: '',
                    img_link: 'assets/img/beers/11.jpeg',
                    photos: [],
                },
                {
                    id: 12,
                    name: 'Название 12',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 2',
                    style: 'Стиль четыре',
                    boxing: '300ml банка',
                    tags: 'Stout - Imperial',
                    ibu: 70,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.43,
                    price: '100',
                    catalog_name: '',
                    img_link: 'assets/img/beers/12.jpeg',
                    photos: [],
                },
                {
                    id: 13,
                    name: 'Название 13',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 3',
                    style: 'Стиль три',
                    boxing: '300ml банка',
                    tags: 'IPA - Triple',
                    ibu: 60,
                    abv: 7.0,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 2.80,
                    price: '170',
                    catalog_name: '',
                    img_link: 'assets/img/beers/13.jpeg',
                    photos: [],
                },
                {
                    id: 14,
                    name: 'Название 14',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 1',
                    style: 'Стиль два',
                    boxing: '1L разлив',
                    tags: 'Stout - Imperial',
                    ibu: 20,
                    abv: 2.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 2.50,
                    price: '130',
                    catalog_name: '',
                    img_link: 'assets/img/beers/14.jpeg',
                    photos: [],
                },
                {
                    id: 15,
                    name: 'Название 15',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 1',
                    style: 'Стиль четыре',
                    boxing: '1L разлив',
                    tags: 'IPA',
                    ibu: 20,
                    abv: 5.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.47,
                    price: '270',
                    catalog_name: '',
                    img_link: 'assets/img/beers/15.jpeg',
                    photos: [],
                },
                {
                    id: 16,
                    name: 'Название 16',
                    desc: '',
                    country: 'Belgium',
                    brewery: 'Пивовар 3',
                    style: 'Стиль три',
                    boxing: '1L разлив',
                    tags: 'IPA - Triple',
                    ibu: 60,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.22,
                    price: '210',
                    catalog_name: '',
                    img_link: 'assets/img/beers/16.jpeg',
                    photos: [],
                },
                {
                    id: 17,
                    name: 'Название 17',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 1',
                    style: 'Стиль четыре',
                    boxing: '1L разлив',
                    tags: 'IPA',
                    ibu: 60,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.43,
                    price: '150',
                    catalog_name: '',
                    img_link: 'assets/img/beers/17.jpeg',
                    photos: [],
                },
                {
                    id: 18,
                    name: 'Название 18',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 4',
                    style: 'Стиль два',
                    boxing: '1L разлив',
                    tags: 'IPA - Triple',
                    ibu: 30,
                    abv: 4.0,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.48,
                    price: '140',
                    catalog_name: '',
                    img_link: 'assets/img/beers/18.jpeg',
                    photos: [],
                },
                {
                    id: 19,
                    name: 'Название 19',
                    desc: '',
                    country: 'Belgium',
                    brewery: 'Пивовар 4',
                    style: 'Стиль три',
                    boxing: '1L разлив',
                    tags: 'Stout - Imperial',
                    ibu: 10,
                    abv: 7.0,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.43,
                    price: '150',
                    catalog_name: '',
                    img_link: 'assets/img/beers/19.jpeg',
                    photos: [],
                },
                {
                    id: 20,
                    name: 'Название 20',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 2',
                    style: 'Стиль пять',
                    boxing: '1L разлив',
                    tags: 'IPA',
                    ibu: 10,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.73,
                    price: '120',
                    catalog_name: '',
                    img_link: 'assets/img/beers/20.jpeg',
                    photos: [],
                },
                {
                    id: 21,
                    name: 'Название 21',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 1',
                    style: 'Стиль пять',
                    boxing: '1L разлив',
                    tags: 'IPA - Triple',
                    ibu: 20,
                    abv: 3.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.60,
                    price: '130',
                    catalog_name: '',
                    img_link: 'assets/img/beers/21.jpeg',
                    photos: [],
                },
                {
                    id: 22,
                    name: 'Название 22',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 2',
                    style: 'Стиль три',
                    boxing: '500ml бутылка',
                    tags: 'Stout - Imperial',
                    ibu: 20,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.43,
                    price: '210',
                    catalog_name: '',
                    img_link: 'assets/img/beers/22.jpeg',
                    photos: [],
                },
                {
                    id: 23,
                    name: 'Название 23',
                    desc: '',
                    country: 'Czech Republic',
                    brewery: 'Пивовар 1',
                    style: 'Стиль два',
                    boxing: '500ml бутылка',
                    tags: 'IPA',
                    ibu: 90,
                    abv: 3.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 2.53,
                    price: '170',
                    catalog_name: '',
                    img_link: 'assets/img/beers/23.jpeg',
                    photos: [],
                },
                {
                    id: 24,
                    name: 'Название 24',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 4',
                    style: 'Стиль пять',
                    boxing: '500ml бутылка',
                    tags: 'Stout - Imperial',
                    ibu: 10,
                    abv: 5.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.80,
                    price: '110',
                    catalog_name: '',
                    img_link: 'assets/img/beers/24.jpeg',
                    photos: [],
                },
                {
                    id: 25,
                    name: 'Название 25',
                    desc: '',
                    country: 'Belgium',
                    brewery: 'Пивовар 1',
                    style: 'Стиль три',
                    boxing: '500ml бутылка',
                    tags: 'IPA',
                    ibu: 10,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.20,
                    price: '140',
                    catalog_name: '',
                    img_link: 'assets/img/beers/25.jpeg',
                    photos: [],
                },
                {
                    id: 26,
                    name: 'Название 26',
                    desc: '',
                    country: 'Russia',
                    brewery: 'Пивовар 2',
                    style: 'Стиль пять',
                    boxing: '500ml бутылка',
                    tags: 'IPA - Triple',
                    ibu: 10,
                    abv: 5.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.25,
                    price: '120',
                    catalog_name: '',
                    img_link: 'assets/img/beers/26.jpeg',
                    photos: [],
                },
                {
                    id: 27,
                    name: 'Название 27',
                    desc: '',
                    country: 'Belgium',
                    brewery: 'Пивовар 2',
                    style: 'Стиль три',
                    boxing: '500ml бутылка',
                    tags: 'IPA - Triple',
                    ibu: 20,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.63,
                    price: '150',
                    catalog_name: '',
                    img_link: 'assets/img/beers/27.jpeg',
                    photos: [],
                },
                {
                    id: 28,
                    name: 'Название 28',
                    desc: '',
                    country: 'Czech Republic',
                    brewery: 'Пивовар 2',
                    style: 'Стиль два',
                    boxing: '500ml бутылка',
                    tags: 'IPA - Triple',
                    ibu: 10,
                    abv: 6.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 3.12,
                    price: '120',
                    catalog_name: '',
                    img_link: 'assets/img/beers/28.png',
                    photos: [],
                },
                {
                    id: 29,
                    name: 'Название 29',
                    desc: '',
                    country: 'Germany',
                    brewery: 'Пивовар 1',
                    style: 'Стиль пять',
                    boxing: '500ml бутылка',
                    tags: 'IPA - Triple',
                    ibu: 10,
                    abv: 4.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 4.49,
                    price: '100',
                    catalog_name: '',
                    img_link: 'assets/img/beers/29.jpeg',
                    photos: [],
                },
                {
                    id: 30,
                    name: 'Название 30',
                    desc: '',
                    country: 'Belgium',
                    brewery: 'Пивовар 1',
                    style: 'Стиль три',
                    boxing: '500ml бутылка',
                    tags: 'Stout - Imperial',
                    ibu: 30,
                    abv: 4.5,
                    untappd_link: 'https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976',
                    untappd_stars: 5.00,
                    price: '110',
                    catalog_name: '',
                    img_link: 'assets/img/beers/30.jpeg',
                    photos: [],
                },
            ]
        }
    },
    getters:{
        DATA: state =>{
            return state.data;
        },
        FILTER_PARAM: state =>{
            return state.filter_param;
        },
        BEERS_SHOW_LIST: state =>{
            return state.beers_show_list;
        },
        FILTRED_BEERS: state =>{
            return state.filtered_beers
        },
        SHOW_FILTRED_BEERS: state =>{
            return state.show_filtered_beers
        },
        FILTER_OPTIONS: state =>{
            return state.filter_options
        },
        CASH_FILTER_OPTIONS: state =>{
            return state.cash_filter_options
        },
        SHOP_CART: state =>{
            return state.shopcart
        }
    },
}
