<template>
  <div id="app">
    <Navbar></Navbar>
    <router-view/>
    <CatalogFilter></CatalogFilter>
    <Shopcart></Shopcart>
  </div>
</template>
<script>
import Navbar from './layouts/Navbar.vue'
import CatalogFilter from './layouts/modals/CatalogFilter.vue'
import Shopcart from './layouts/modals/Shopcart.vue'

export default {
  components:{Navbar, CatalogFilter, Shopcart},
  methods: {},
  mounted() {
    /*this.set_filter_param()*/
  }
}
</script>
<style>

</style>
