<template>
  <div class="modal fade" id="modal-filter" tabindex="-1" aria-labelledby="exampleModalFullscreenLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title h4" id="exampleModalFullscreenLabel">Фильтры</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="" style="overflow-y: scroll; height: 100%; padding-left: 10px;">

            <!-- Single Param-->
            <template v-for="(opt, index) in $store.getters.FILTER_OPTIONS">
              <div class="accordion" :id="'accordionExample' + index">
                <div class="accordion-item">
                  <h2 class="accordion-header" :id="'headingOne' + index">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + index" aria-expanded="true" aria-controls="collapseOne">
                      {{ opt.title }}
                    </button>
                  </h2>
                  <div :id="'collapseOne' + index" class="accordion-collapse collapse show" :aria-labelledby="'headingOne' + index" :data-bs-parent="'#accordionExample1' + index">
                    <div class="accordion-body">
                      <div v-if="opt.type == 'checkbox'">
                        <Checkbox :filter_options="opt"></Checkbox>
                      </div>
                      <div v-else="opt.type == 'slider'">
                        <FilterSlider :filter_options="opt"></FilterSlider>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </template>

          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between ">
          <button type="button" class="btn btn-secondary" @click="reset_filter">Сбросить</button>
          <button type="button" class="btn filter-submit" data-bs-dismiss="modal" @click="show_filtered_beers">Показать <span class="badge rounded-pill bg-light text-dark">{{ beers_count }}</span></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from '@/components/FilterCheckbox'
import FilterSlider from '@/components/FilterSlider'

var arr = require('lodash');

export default {
  components:{
    Checkbox, FilterSlider
  },
  computed:{
    beers_count:function(){
      return this.$store.getters.FILTRED_BEERS.length
    },
    beers: {
      get(){
        return this.$store.getters.DATA.beers
      }
    },
    filtred_beers: {
      get(){
        return this.$store.getters.FILTRED_BEERS
      }
    }
  },

  methods:{
    start_filteration: function(){
       console.log( 'start filtration' )
        this.country_filter(this.beers)
        this.brewery_filter(this.filtred_beers)
        this.style_filter(this.filtred_beers)
        this.boxing_filter(this.filtred_beers)
        this.tags_filter(this.filtred_beers)
        this.ibu_filter(this.filtred_beers)
        this.abv_filter(this.filtred_beers)
        /*this.untappd_stars_filter(this.filtred_beers)*/
        this.price_filter(this.filtred_beers)
    },
    country_filter: function(beers){
      let list_country = this.$store.getters.BEERS_SHOW_LIST.country
      let filtered = beers.filter(beer =>list_country.indexOf(beer.country) !== -1 )
      this.store_filtered_beers(filtered)
    },
    brewery_filter: function(beers){
      let list_brewery = this.$store.getters.BEERS_SHOW_LIST.brewery
      let filtered = beers.filter(beer =>list_brewery.indexOf(beer.brewery) !== -1 )
      this.store_filtered_beers(filtered)
    },
    style_filter: function(beers){
      let list_style = this.$store.getters.BEERS_SHOW_LIST.style
      let filtered = beers.filter(beer =>list_style.indexOf(beer.style) !== -1 )
      this.store_filtered_beers(filtered)
    },
    boxing_filter: function(beers){
      let list_boxing = this.$store.getters.BEERS_SHOW_LIST.boxing
      let filtered = beers.filter(beer =>list_boxing.indexOf(beer.boxing) !== -1 )
      this.store_filtered_beers(filtered)
    },
    tags_filter: function(beers){
      let list_tags = this.$store.getters.BEERS_SHOW_LIST.tags
      let filtered = beers.filter(beer =>list_tags.indexOf(beer.tags) !== -1 )
      this.store_filtered_beers(filtered)
    },
    ibu_filter: function(beers){
      let ibu_vals = this.$store.getters.BEERS_SHOW_LIST.ibu // [12, 85]
      let filtered = beers.filter(beer => beer.ibu >= ibu_vals[0] &&  beer.ibu <= ibu_vals[1])
      this.store_filtered_beers(filtered)
    },
    abv_filter: function(beers){
      let abv_vals = this.$store.getters.BEERS_SHOW_LIST.abv // [12, 85]
      let filtered = beers.filter(beer => beer.abv >= abv_vals[0] &&  beer.abv <= abv_vals[1])
      this.store_filtered_beers(filtered)
    },
    price_filter: function(beers){
      let price_vals = this.$store.getters.BEERS_SHOW_LIST.price // [12, 85]
      let filtered = beers.filter(beer => beer.price >= price_vals[0] &&  beer.price <= price_vals[1])
      this.store_filtered_beers(filtered)
    },
    untappd_stars_filter: function(beers){
      let list_untappd_stars = this.$store.getters.BEERS_SHOW_LIST.untappd_stars
      let filtered = beers.filter(beer =>list_untappd_stars.indexOf(Math.floor(beer.untappd_stars)) !== -1 )
      this.store_filtered_beers(filtered)
    },

    store_filtered_beers: function(filtered_beers){
      this.$store.commit('set_filtered_beers', filtered_beers)
    },

    beers_show_list: function(){
      let filter_options = this.$store.getters.FILTER_OPTIONS
      let beers_show_list = []

      // country: ['Russia', 'Germany'],
      filter_options.map(option => beers_show_list[option.name] = this.vals_beers_show_list(option))

      this.$store.commit('set_beers_show_list', beers_show_list)
      this.start_filteration()
      console.log( beers_show_list )
    },

    vals_beers_show_list:function(option){
      let vals = []

      if(option.type == "checkbox"){
        option.params.map(param => param.val === true ? vals.push(param.name) : '')

      }else if(option.type == "slider"){
        vals = option.params.val
      }

      return vals
    },

    show_filtered_beers:function(){
      this.$store.commit('set_show_filtered_beers', this.$store.getters.FILTRED_BEERS)
    },
    cash_filter_param:function(){
      let cloned = arr.cloneDeep(this.$store.getters.FILTER_OPTIONS);

      this.$store.commit('set_cash_filter_options', cloned)
    },
    reset_filter: function(){
      let cloned = arr.cloneDeep(this.$store.getters.CASH_FILTER_OPTIONS);
      this.$store.commit('set_filter_options', cloned)
      this.beers_show_list()
      this.start_filteration()
    },
  },
  mounted() {
    this.beers_show_list()
    this.start_filteration()
    this.show_filtered_beers()
    this.cash_filter_param()
  }
}
</script>