<template>
  <section id="untappd">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <img src="assets/img/index-untappd/untappd_logo.png" alt="" class="untappd_logo w-25 m-4">
        </div>
        <div class="col-12">
          <p class="px-2 mb-0 text-center">
            Отзывы, рейтинг и много интересной информации на нашей страничке в Untappd.
          </p>
        </div>
        <div class="col-12 py-4 d-flex justify-content-center">
          <a href="/page-untappd.php" class="about-untappd px-4 mx-1"> об Untappd</a>
          <a href="https://untappd.com/v/bottle-expert/9370855" class="add-to-cart px-4 mx-1"> перейти </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

}
</script>
<style>
#untappd {
  background: #F5F5F5;
}
.about-untappd{
  display: block;
  font-family: Roboto-Medium;
  padding: 3px 8px;
  border-radius: 2px;
  text-decoration: none;
  color: #FAFAFA;
  font-size: 10px;
  text-align: center;
  background: #424242;
}
</style>
