<template>
  <div>
    <br><br>
    <Slider
        v-model="filter_options.params.val"
        :min="filter_options.params.min"
        :max="filter_options.params.max"
        @change="$parent.beers_show_list()"
    />
  </div>
</template>
<script>
/* https://vuejsexamples.com/vue-3-slider-component-with-multihandles-and-formatting/ */
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)
import Slider from '@vueform/slider/dist/slider.vue2.js'
import styles from '@vueform/slider/themes/default.css'
export default {
  props:['filter_options'],
  components: { Slider },
  data: () => ({
    value: [20, 40]
  }),
  computed:{
    /*val: {
       get(){
        return this.param
      },
      set(val){
        this.filter_options.param.val = val
      }
    },*/
  }
}
</script>
<style>

.slider-connect {
  background: #ffc000;
  cursor: pointer;
}
.slider-tooltip {
  position: absolute;
  display: block;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #212121;
  background: #212121;
}
</style>