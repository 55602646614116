<template>
  <section id="interior" class="dark-brand-bg">
    <div class="container">
      <div class="row py-4">
        <div class="col-12">
          <h3 class="text-center pt-2">Интерьер</h3>
          <p class="text-center">Место для отдыха в компании друзей</p>
          <div id="interior_photos" class="list-unstyled row">
            <a href="assets/img/interior/1.jpg" class="col-6">
              <img src="assets/img/interior/thumb-1.jpg" class="full-img"/>
            </a>
            <a href="assets/img/interior/2.jpg"  class="col-6">
              <img src="assets/img/interior/thumb-2.jpg" class="full-img"/>
            </a>
            <a href="assets/img/interior/3.jpg"  class="col-6">
              <img src="assets/img/interior/thumb-3.jpg" class="full-img"/>
            </a>
            <a href="assets/img/interior/4.jpg"  class="col-6">
              <img src="assets/img/interior/thumb-4.jpg" class="full-img"/>
            </a>
            <a href="assets/img/interior/5.jpg"  class="col-6">
              <img src="assets/img/interior/thumb-5.jpg" class="full-img"/>
            </a>
            <a href="assets/img/interior/6.jpg"  class="col-6">
              <img src="assets/img/interior/thumb-6.jpg" class="full-img"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>
<style>
  #interior{
    background: #fff;
    color: #F5F5F5;
    border-radius: 10px 10px 0 0;
  }
  #interior h3, #interior  p{
    color: #212121;
  }
  #interior a{
    padding: 0;
    border:5px solid #fff;
  }
  #interior .full-img{
    width: 100%;
  }
  #interior_photos{
    padding: 0 5px 0 5px;
  }
</style>