<template>
  <section id="tags-index" class="dark-brand-bg">
    <div class="container">
      <div class="row py-4">
        <div class="col-12 text-center">
          <span class="badge bg-warning text-dark p-3"><a href="#new-beer">Новинки</a></span>
          <span class="badge bg-warning text-dark p-3"><a href="#popular-beer">Популярное</a></span>
          <span class="badge bg-warning text-dark p-3"><a href="#recommen-beer">Рекомендуем</a></span>
          <span class="badge bg-warning text-dark p-3"><a href="#interior">Интерьер</a></span>
          <span class="badge bg-warning text-dark p-3"><a href="#merch">Мерч</a></span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

}
</script>
<style>
#tags-index{
  background: #F5F5F5;
}
#tags-index .badge{
  padding: 10px !important;
  margin-bottom: 4px !important;
}
#tags-index .badge a{
  color: #212121;
}
</style>