import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

import SingleCatalog from '../views/SingleCatalog.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/catalog',
    name: 'catalog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Catalog.vue')
  },
  {
    path: '/snacks',
    name: 'snacks',
    component: () => import('../views/Snacks.vue')
  },
  {
    path: '/merch',
    name: 'merch',
    component: () => import('../views/Merch.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import('../views/Buy.vue')
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('../views/Delivery.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/Contacts.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router
