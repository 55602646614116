<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light dark">
    <div class="container-fluid">

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!--<a class="navbar-brand" href="#">
        <img src="assets/img/logo.svg" alt="" width="80" height="auto">
      </a>-->

      <p class="text-center pt-3" style="line-height: 12px;">
        <a href="/">
          <span class="bar-name">BOTTLE EXPERT</span> <br>
          <span class="bar-slogan">BEER & CIDER</span>
        </a>
      </p>


      <a class="shop-icon"  href="#!" style="padding: 4px 8px;" data-bs-toggle="modal" data-bs-target="#modal-shopcart">
        <img src="assets/img/shop-icon.svg" alt="" width="25" height="auto" >
        <span class="badge rounded-pill bg-warning text-dark" style="margin-left: -10px; margin-top: -50px;" v-if="products_count > 0">{{ products_count }}</span>
      </a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-3 mb-lg-0">
          <li class="nav-item" @click="close_menu">
            <router-link to="/" class="nav-link text-center">ГЛАВНАЯ</router-link>
          </li>
          <li class="nav-item" @click="close_menu">
            <router-link to="/catalog" class="nav-link text-center">ПИВО И СИДР</router-link>
          </li>
          <li class="nav-item" @click="close_menu">
            <router-link to="/snacks" class="nav-link text-center">ЗАКУСКИ</router-link>
          </li>
          <li class="nav-item" @click="close_menu">
            <router-link to="/merch" class="nav-link text-center">МЕРЧ</router-link>
          </li>
          <li class="nav-item" @click="close_menu">
            <router-link to="/about" class="nav-link text-center">О НАС</router-link>
          </li>
          <li class="nav-item" @click="close_menu">
            <router-link to="/buy" class="nav-link text-center">КУПИТЬ</router-link>
          </li>
          <li class="nav-item" @click="close_menu">
            <router-link to="/delivery" class="nav-link text-center">ДОСТАВКА</router-link>
          </li>
          <li class="nav-item" @click="close_menu">
            <router-link to="/contacts" class="nav-link text-center">КОНТАКТЫ</router-link>
          </li>
        </ul>
      </div>

    </div>
  </nav>
</template>

<script>

  export default {
  name: 'Navbar',
  computed:{
    products_count:function(){
      return this.$store.getters.SHOP_CART.length
    },
  },
  methods:{
    close_menu:function(){
      let myCollapse = document.getElementById('navbarSupportedContent')
      let bsCollapse = new bootstrap.Collapse(myCollapse, {
        toggle: true
      })
    },
  }

}
</script>
