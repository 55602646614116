<template>
  <section id="merch" class="dark-brand-bg">
    <div class="container">
      <div class="row py-4">
        <div class="col-12 text-center">
          <h3 class="text-center py-2">Мерч Bottle Expert</h3>
          <img src="/assets/img/tshort.jpg" alt="" class="w-100 rounded">
          <p class="pt-3">
            <strong>
              Мерч от Bottle Expert это эксклюзивные футболки, толстовки и пивная атрибутика.
            </strong>
          </p>
          <a href="/page-merch.php" class="btn btn-dark btn-sm px-4">  Подробнее </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

}
</script>
<style>
#merch{
  border-radius: 10px 10px 0 0;
  background-color: #FDD835;
}
</style>