<template>
  <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div style="background-image: url(/assets/img/bg-beer.jpg);" class="slider-inner">
          <img src="assets/img/logo.svg" alt="" width="120" height="auto" class="my-3">
          <h1>Крафтовое</h1>
          <h5>российское и зарубежное</h5>
        </div>
      </div>
      <div class="carousel-item">
        <div style="background-image: url(/assets/img/bg-beer2.jpg);" class="slider-inner">
          <img src="assets/img/logo-yelow.svg" alt="" width="120" height="auto"  class="my-3">
          <h1 class="black-text-bg mb-0">Бутылочное пиво</h1>
          <h5 class="black-text-bg">эксклюзивные новинки</h5>
        </div>
      </div>
      <div class="carousel-item">
        <div style="background-image: url(/assets/img/bg-beer3.jpg);" class="slider-inner">
          <img src="assets/img/logo.svg" alt="" width="120" height="auto" class="my-3">
          <h1>Сидр</h1>
          <h5>идеальный аперитив</h5>
        </div>
      </div>
      <div class="carousel-item">
        <div style="background-image: url(/assets/img/bg-beer4.jpg);" class="slider-inner">
          <!--<img src="assets/img/logo.svg" alt="" width="120" height="auto" class="my-3">-->
          <div style="height: 160px;"></div>
          <!--<h1 class="black-text-bg mb-0"></h1>
          <h5 class="black-text-bg"></h5>-->
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>
  </div>
</template>
<script>
export default {

}
</script>

