<template>
  <section id="location" class="brand-bg">
    <div class="container">
      <div class="row py-4">
        <div class="col-12">
          <h3 class="text-center py-2">Наша локация</h3>
          <p class="text-center">Энгельса пр., 134 к3, Санкт-Петербург</p>
          <br>
          <a href="https://yandex.ru/maps/-/CCUMzZFp0B">
            <img src="/assets/img/map.jpg" alt="" class="w-100">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

}
</script>
<style>
#location{
  background: #212121;
  color: #F5F5F5;
}
</style>
