<template>
  <div>
    <slider></slider>
    <tagsIndex></tagsIndex>
    <newer></newer>
    <popular></popular>
    <recommen></recommen>
    <interior></interior>
    <merch></merch>
    <untappd></untappd>
    <location></location>
  </div>
</template>
<script>
import interior from '@/components/index-parts/Interior.vue'
import location from '@/components/index-parts/Location.vue'
import merch from '@/components/index-parts/Merch.vue'
import newer from '@/components/index-parts/New.vue'
import popular from '@/components/index-parts/Popular.vue'
import recommen from '@/components/index-parts/Recommen.vue'
import slider from '@/components/index-parts/Slider.vue'
import tagsIndex from '@/components/index-parts/TagsIndex.vue'
import untappd from '@/components/index-parts/Untappd.vue'

export default {
  components:{
    interior, location, merch, newer, popular, recommen, slider, tagsIndex, untappd
  }
}
</script>
<style>

</style>