<template>
  <section id="recommen-beer"  class="brand-bg">
    <div class="container">
      <div class="row py-4">
        <div class="col-12">
          <h3 class="text-center pt-2">Рекомендуем</h3>
          <p class="text-center">Выбор наших экспертов</p>

          <div class="list-group beers-list">
            <!--Single Beer-->
            <div class="list-group-item list-group-item-action <!--active-->" aria-current="true">
              <div class="d-flex w-100 justify-content-start">
                <div class="und-img">
                  <a href="/single.php" class="to-single">
                    <img src="assets/img/beers/5.jpeg" alt="" class="w-100 mb-2">
                  </a>
                  <a href="#!" class="add-to-cart"> в корзину </a>
                </div>
                <div class="w-100">
                  <a href="/single.php" class="to-single">
                    <h5 class="mb-1">Ogorod Black Currant  </h5>
                  </a>
                  <small>Sour - Fruited Berliner Weisse</small>
                  <p class="mb-1">4.7% ABV • 8 IBU • Pivot Point </p>
                  <small>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star-half-alt"></i>
                    <i class="far fa-star"></i>
                    &nbsp;
                    <span>3.43</span>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976" class="und-link">untappd</a>
                  </small>
                  <p class="dots">
                    <span class="size">400ml Draft</span>
                    <span class="price">520 р.</span>
                  </p>
                </div>
              </div>
            </div>
            <!--/Single Beer-->

            <!--Single Beer-->
            <div class="list-group-item list-group-item-action <!--active-->" aria-current="true">
              <div class="d-flex w-100 justify-content-start">
                <div class="und-img">
                  <a href="/single.php" class="to-single">
                    <img src="assets/img/beers/3.jpeg" alt="" class="w-100 mb-2">
                  </a>
                  <a href="#!" class="add-to-cart"> в корзину </a>
                </div>
                <div class="w-100">
                  <a href="/single.php" class="to-single">
                    <h5 class="mb-1">Ogorod Peach & Yuzu  </h5>
                  </a>
                  <small>Sour - Fruited Berliner Weisse</small>
                  <p class="mb-1">4.7% ABV • 8 IBU • Pivot Point  </p>
                  <small>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star-half-alt"></i>
                    <i class="far fa-star"></i>
                    &nbsp;
                    <span>3.43</span>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976" class="und-link">untappd</a>
                  </small>
                  <p class="dots">
                    <span class="size">400ml Draft</span>
                    <span class="price">260 р.</span>
                  </p>
                </div>
              </div>
            </div>
            <!--/Single Beer-->

            <!--Single Beer-->
            <div class="list-group-item list-group-item-action <!--active-->" aria-current="true">
              <div class="d-flex w-100 justify-content-start">
                <div class="und-img">
                  <a href="/single.php" class="to-single">
                    <img src="assets/img/beers/2.jpeg" alt="" class="w-100 mb-2">
                  </a>
                  <a href="#!" class="add-to-cart"> в корзину </a>
                </div>
                <div class="w-100">
                  <a href="/single.php" class="to-single">
                    <h5 class="mb-1">Ogorod Raspberry & Lingonberry  </h5>
                  </a>
                  <small>Sour - Fruited Berliner Weisse</small>
                  <p class="mb-1">4.7% ABV • 8 IBU • Pivot Point  </p>
                  <small>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star-half-alt"></i>
                    <i class="far fa-star"></i>
                    &nbsp;
                    <span>3.43</span>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://untappd.com/b/pivot-point-belka-ya-lyublyu-tebya/3870976" class="und-link">untappd</a>
                  </small>
                  <p class="dots">
                    <span class="size">400ml Draft</span>
                    <span class="price">310 р.</span>
                  </p>
                </div>
              </div>
            </div>
            <!--/Single Beer-->


          </div>

        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

}
</script>
<style>
#recommen-beer{
  background: #212121;
  color: #F5F5F5;

}

</style>
